import React from 'react';
import useContent from '@hooks/useContent/useContent';
import { Container, Row, Col } from 'reactstrap';

const Hero = () => {
  const { getContent } = useContent();
  return (
    <section className="section py-5 text-dark">
      <Container>
        <Row className="justify-content-center">
          <Col xs="12" className="mx-auto text-center">
            <h2 className="display-3 text-dark-gray font-weight-bolder">
              {getContent('header-title')}
            </h2>
            <p className="font-weight-light display-4 mt-5">
              {getContent('header-line1')}
            </p>
            <p className="font-weight-light display-4 mt-2">
              {getContent('header-line2')}
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
