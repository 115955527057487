import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import config from '@data/SiteConfig';
import TwitterIcon from '@images/twitter.svg';

const Header = () => {
  const { logo } = useStaticQuery(graphql`
    query LogoQuery {
      logo: file(relativePath: { eq: "logo.png" }) {
        childCloudinaryAsset {
          fixed(width: 350) {
            ...cloudinaryAssetFixed_noBase64
          }
        }
      }
    }
  `);
  return (
    <header>
      <Container className="py-2">
        <Row className="justify-content-center">
          <Col lg="6" className="mx-sm-auto text-center">
            <Link to="/">
              <Img
                fixed={logo.childCloudinaryAsset.fixed}
                alt={config.siteTitle}
              />
            </Link>
          </Col>
          <Col
            lg="6"
            className="mx-md-auto text-center d-flex justify-content-center justify-content-lg-end mt-2 mt-lg-0"
          >
            <a
              href={`https://twitter.com/intent/tweet?text=Check out ${config.siteUrl} A curated list of resources for podcasters!`}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-twitter rounded-lg animated-grow-1 font-weight-bolder align-self-center"
            >
              <span className="d-none d-md-inline-block">Share on Twitter</span>
              <span className="d-md-none">Share</span>
              <img
                className="ml-1 twitter-icon"
                src={TwitterIcon}
                alt="Share on Twitter"
              />
            </a>
            <a
              className="btn btn-primary rounded-lg animated-grow-1 ml-3 font-weight-bolder align-self-center"
              target="_blank"
              rel="noopener noreferrer"
              href={config.formURL}
            >
              Suggest a Resource
            </a>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
