const getPlausible = () => {
  if (typeof window.plausible === 'function') {
    return window.plausible;
  }
  return false;
};

const usePlausible = () => {
  const trackEvent = (eventName, { callback: userCallback } = {}) => {
    return new Promise((resolve) => {
      const plausible = getPlausible();
      if (plausible) {
        plausible(eventName, {
          callback: () => {
            resolve('ok');
            userCallback && userCallback();
          },
        });
      } else {
        resolve('Plausible not found');
      }
    });
  };

  return {
    trackEvent,
  };
};

export default usePlausible;
