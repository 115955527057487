import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import config from '@data/SiteConfig';
import useContent from '@hooks/useContent/useContent';
import OutboundLink from '@components/OutboundLink/OutboundLink';

const Footer = () => {
  const { getContent } = useContent();
  return (
    <footer className="bg-secondary">
      <Container>
        <Row className="justify-content-center py-5">
          <Col lg="10" className="text-center">
            <h2 className="font-weight-bolder text-white mb-4">
              {getContent('footer-cta-text')}
            </h2>

            <OutboundLink
              eventName={`footerSlackClick`}
              className="slack-link w-75 btn btn-link text-decoration-none font-weight-bolder btn-white py-2 m-auto animated-grow-1"
              href={config.slackURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getContent('footer-cta-link-text')}
            </OutboundLink>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
