import React from 'react';
import PropTypes from 'prop-types';

import Header from '@components/Header/Header';
import Hero from '@components/Hero/Hero';
import HeroRibbon from '@components/HeroRibbon/HeroRibbon';
import Footer from '@components/Footer/Footer';
//import ToTop from '@components/ToTop/ToTop';
import ProductHuntBadge from '@components/ProductHuntBadge/ProductHuntBadge';

import '@scss/styles.scss';

const Layout = ({ children, hideHero = false }) => (
  <>
    <div className="bg-white">
      <Header />
      {!hideHero && (
        <>
          <Hero />
          <HeroRibbon />
        </>
      )}
    </div>
    <main role="main" id="content">
      {children}
    </main>
    {/* <ToTop /> */}
    <ProductHuntBadge />
    <Footer />
  </>
);

Layout.propTypes = {
  /** Components to render in layout **/
  children: PropTypes.node.isRequired,
};

export default Layout;
