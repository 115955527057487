import React from 'react';

import usePlausible from '@hooks/usePlausible/usePlausible';

const OutboundLink = ({
  href,
  target,
  children,
  eventName,
  eventOptions = {},
  ...props
}) => {
  const { trackEvent } = usePlausible();

  return (
    <a
      {...props}
      href={href}
      target={target}
      onClick={async (e) => {
        let redirect = true;
        if (
          e.button !== 0 ||
          e.altKey ||
          e.ctrlKey ||
          e.metaKey ||
          e.shiftKey ||
          e.defaultPrevented
        ) {
          redirect = false;
        }
        if (target && target.toLowerCase() !== `_self`) {
          redirect = false;
        }
        if (eventName) {
          await trackEvent(eventName, eventOptions);
        }
        if (redirect) {
          document.location = href;
        }
        return false;
      }}
    >
      {children}
    </a>
  );
};

export default OutboundLink;
