import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import config from '@data/SiteConfig';
import useContent from '@hooks/useContent/useContent';
import OutboundLink from '@components/OutboundLink/OutboundLink';

const HeroRibbon = () => {
  const { getContent } = useContent();
  return (
    <section className="section bg-hero-ribbon py-3 text-dark">
      <Container>
        <Row className="justify-content-center">
          <Col xs="10" className="mx-auto text-center ">
            <p className="mb-0 text-white font-weight-bold">
              {getContent('slack-cta-text')}
              <OutboundLink
                eventName={`heroSlackClick`}
                className="text-decoration-underline font-weight-bolder d-inline-block animated-grow-1"
                href={config.slackURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {getContent('slack-cta-link-text')}
              </OutboundLink>
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroRibbon;
