const config = {
  siteTitle: 'CasterKit - Resources for Podcasters',
  siteTitleShort: 'CasterKit',
  siteLogo: '/logos/logo-600.png',
  siteImage: '/card.png',
  siteIcon: 'static/logos/icon.png',
  siteUrl: 'https://casterkit.com',
  pathPrefix: '/',
  supportEmail: 'dan@schoonlabs.com',
  formURL: 'https://forms.gle/TfGjxgTweDU76zBY8',
  slackURL:
    'https://casterkit.slack.com/join/shared_invite/zt-df6e62yu-Rp8a6bUF1h_S3J1LJyYI2w#',
  siteDescription:
    "Curated list of resources for podcasters. We're sharing what we've learned so you don't have to start at square one.",
  themeColor: '#5e17eb',
  backgroundColor: '#f7f9fb',
};

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === '/') {
  config.siteUrl = config.siteUrl.slice(0, -1);
}

module.exports = config;
