import { useStaticQuery, graphql } from 'gatsby';

const useContent = () => {
  const {
    allContent: { nodes: content },
  } = useStaticQuery(graphql`
    query ContentQuery {
      allContent: allContent {
        nodes {
          key
          value
        }
      }
    }
  `);
  const getContent = (key) => {
    return content.find((content) => content.key === key).value;
  };

  return {
    getContent,
  };
};

export default useContent;
